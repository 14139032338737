import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Text,
  Input,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  HStack,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';

import { useStore } from '../../hooks/useStore';

const TagsScreen = () => {
  const [title, setTitle] = useState('');
  const [loading, setLoading] = useState(false);
  const {
    problemStore: { tags, fetchTags, createTag },
  } = useStore();

  useEffect(() => {
    fetchTags();
  }, [fetchTags]);

  const handleCreateCategory = async () => {
    setLoading(true);
    await createTag(title);
    setTitle('');
    setLoading(false);
  };

  // Use responsive padding
  const padding = useBreakpointValue({ base: 4, md: 8 });

  return (
    <Box padding={padding}>
      <Text fontSize={['xl', '2xl']} fontWeight="bold" mt={12} mb={4}>
        Tags
      </Text>
      <Divider />
      <HStack mt={8} alignItems={'flex-end'} spacing={4}>
        <FormControl flex={3} isRequired>
          <FormLabel>Title</FormLabel>
          <Input
            bg="white"
            value={title}
            placeholder="Add tag's name"
            onChange={(e) => setTitle(e.target.value)}
            boxShadow="0 1px 3px rgba(0, 0, 0, 0.1)"
          />
        </FormControl>
        <Button flex={1} colorScheme="primary" isLoading={loading} onClick={handleCreateCategory}>
          Create
        </Button>
      </HStack>
      <Box overflowX="auto" mt={16}>
        <Table variant="striped" colorScheme="gray">
          <Thead>
            <Tr>
              <Th>ID</Th>
              <Th>Title</Th>
            </Tr>
          </Thead>
          <Tbody>
            {tags.map((tag) => (
              <Tr key={tag.id}>
                <Td>{tag.id}</Td>
                <Td>{tag.name}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
};

export default observer(TagsScreen);
