import React from 'react';
import { MultiSelect as AMultiSelect } from 'react-multi-select-component';

type Props = {
  value: { label: string; value: any; disabled?: boolean }[];
  options: { label: string; value: any; disabled?: boolean }[];
  onChange: (value: any[]) => void;
  label: string;
};

export const MultiSelect = ({ options, value, onChange, label }: Props) => {
  return <AMultiSelect options={options} value={value} onChange={onChange} labelledBy={label} />;
};
