import React, { useState, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../hooks/useStore';
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  VStack,
  useToast,
  Box,
  AspectRatio,
} from '@chakra-ui/react';

const inputStyles = {
  border: 'none',
  boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
  bg: 'gray.300',
};

interface CreateVideoCourseDrawerProps {
  isOpen: boolean;
  onClose: () => void;
}

const CreateVideoCourseDrawer: React.FC<CreateVideoCourseDrawerProps> = ({ isOpen, onClose }) => {
  const {
    courseStore: { createCourse, loading },
  } = useStore();
  const toast = useToast();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [introVideo, setIntroVideo] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setIntroVideo(file);
      setPreviewUrl(URL.createObjectURL(file));
    }
  };

  const handleCreateCourse = async () => {
    if (!introVideo) {
      toast({
        title: 'Error',
        description: 'Please upload an intro video first.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const formData = new FormData();
      formData.append('title', title);
      formData.append('description', description);
      formData.append('video', introVideo);

      await createCourse(formData);

      toast({
        title: 'Course created',
        description: `Successfully created course: ${title}`,
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-left',
      });

      resetForm();
      onClose();
    } catch (error) {
      toast({
        title: 'Error creating course',
        description: 'An error occurred while creating the course.',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-left',
      });
    }
  };

  const resetForm = () => {
    setTitle('');
    setDescription('');
    setIntroVideo(null);
    setPreviewUrl(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Create New Video Course</DrawerHeader>
        <DrawerBody>
          <VStack spacing={4}>
            <FormControl isRequired>
              <FormLabel>Intro Video</FormLabel>
              <Input type="file" accept="video/*" onChange={handleFileChange} ref={fileInputRef} {...inputStyles} />
            </FormControl>
            {previewUrl && (
              <Box width="100%">
                <AspectRatio ratio={16 / 9}>
                  <video src={previewUrl} controls>
                    Your browser does not support the video tag.
                  </video>
                </AspectRatio>
              </Box>
            )}
            <FormControl isRequired>
              <FormLabel>Title</FormLabel>
              <Input
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Enter course title"
                isDisabled={!introVideo}
                {...inputStyles}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Description</FormLabel>
              <Textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Enter course description"
                isDisabled={!introVideo}
                {...inputStyles}
              />
            </FormControl>
          </VStack>
        </DrawerBody>
        <DrawerFooter>
          <Button variant="outline" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="primary" onClick={handleCreateCourse} isLoading={loading} isDisabled={!introVideo}>
            Create Course
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default observer(CreateVideoCourseDrawer);
