import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../hooks/useStore';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  VStack,
  Heading,
  useToast,
  Divider,
} from '@chakra-ui/react';
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';

import { ProblemDifficulty } from '../../constants/enums';
import { IProblemTest } from '../../models/problem';
import { MultiSelect } from '../../components/common/MultiSelect';

const inputStyles = {
  border: 'none',
  boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
  bg: 'white',
};

const CreateProblemScreen = () => {
  const {
    problemStore: { fetchCategories, createProblem, fetchTags, loading, categories, tags },
  } = useStore();

  const toast = useToast();

  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [solutionTitle, setSolutionTitle] = useState('');
  const [solutionText, setSolutionText] = useState('');
  const [difficulty, setDifficulty] = useState<ProblemDifficulty>(ProblemDifficulty.Easy);
  const [categoryId, setCategoryId] = useState<number>(1);
  const [tests, setTests] = useState<Partial<IProblemTest>[]>([{ input: '', output: '' }]);
  const [selectedTags, setSelectedTags] = useState<any[]>([]);

  useEffect(() => {
    fetchCategories();
    fetchTags();
  }, [fetchCategories, fetchTags, toast]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      await createProblem({
        title,
        text,
        difficulty,
        categoryId,
        tests,
        tags: selectedTags.map((tag) => tag.value),
        solutions: [
          {
            title: solutionTitle,
            text: solutionText,
            userId: 1,
            languageId: 76,
          },
        ],
      });

      toast({
        title: 'Problem created',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });

      // Clear out the fields
      setTitle('');
      setText('');
      setDifficulty(ProblemDifficulty.Easy);
      setCategoryId(1);
      setTests([{ input: '', output: '' }]);
      setSelectedTags([]);
      setSolutionTitle('');
      setSolutionText('');
    } catch (error) {
      toast({
        title: 'Error creating problem',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  const handleAddTest = () => {
    setTests([...tests, { input: '', output: '' }]);
  };

  const handleRemoveTest = (index: number) => {
    setTests(tests.filter((_, i) => i !== index));
  };

  const handleTestChange = (index: number, field: 'input' | 'output', value: string) => {
    const newTests = [...tests];
    newTests[index][field] = value;
    setTests(newTests);
  };

  return (
    <Box maxWidth={['100%', '100%', '800px']} margin="auto" padding={[4, 6, 8]}>
      <Heading mb={[4, 5, 6]} fontSize={['xl', '2xl', '3xl']}>
        Create New Problem
      </Heading>
      <form onSubmit={handleSubmit}>
        <VStack spacing={[4, 5, 6]} align="stretch">
          <FormControl isRequired>
            <FormLabel>Title</FormLabel>
            <Input value={title} onChange={(e) => setTitle(e.target.value)} {...inputStyles} />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Text</FormLabel>
            <Textarea value={text} onChange={(e) => setText(e.target.value)} minHeight="150px" {...inputStyles} />
          </FormControl>
          <Divider />
          <FormControl isRequired>
            <FormLabel>Solution Title</FormLabel>
            <Input value={solutionTitle} onChange={(e) => setSolutionTitle(e.target.value)} {...inputStyles} />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Solution text</FormLabel>
            <Textarea
              value={solutionText}
              onChange={(e) => setSolutionText(e.target.value)}
              minHeight="150px"
              {...inputStyles}
            />
          </FormControl>
          <Divider />
          <FormControl isRequired>
            <FormLabel>Difficulty</FormLabel>
            <Select value={difficulty} onChange={(e) => setDifficulty(Number(e.target.value))} {...inputStyles}>
              {Object.entries(ProblemDifficulty)
                .filter(([key]) => isNaN(Number(key)))
                .map(([key, value]) => (
                  <option key={key} value={value}>
                    {key}
                  </option>
                ))}
            </Select>
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Category</FormLabel>
            <Select value={categoryId} onChange={(e) => setCategoryId(Number(e.target.value))} {...inputStyles}>
              {categories?.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.title}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>Tests</FormLabel>
            {tests.map((test, index) => (
              <VStack key={index} mb={4} spacing={2} align="stretch">
                <Textarea
                  placeholder="Input"
                  value={test.input}
                  onChange={(e) => handleTestChange(index, 'input', e.target.value)}
                  {...inputStyles}
                />
                <Textarea
                  placeholder="Output"
                  value={test.output}
                  onChange={(e) => handleTestChange(index, 'output', e.target.value)}
                  {...inputStyles}
                />
                <Button
                  leftIcon={<DeleteIcon />}
                  onClick={() => handleRemoveTest(index)}
                  size="sm"
                  colorScheme="danger"
                  variant="outline">
                  Remove Test
                </Button>
              </VStack>
            ))}
            <Button leftIcon={<AddIcon />} onClick={handleAddTest} size="sm" mt={2}>
              Add Test
            </Button>
          </FormControl>
          <FormControl>
            <FormLabel>Tags</FormLabel>
            <MultiSelect
              label="Tags"
              value={selectedTags || []}
              onChange={(values) => setSelectedTags(values)}
              options={tags.map((tag) => ({ label: tag.name, value: tag.id }))}
            />
          </FormControl>
          <Button type="submit" colorScheme="primary" isLoading={loading} size={['md', 'lg']} width="100%" mt={4}>
            Create Problem
          </Button>
        </VStack>
      </form>
    </Box>
  );
};

export default observer(CreateProblemScreen);
