import React, { useEffect, useState } from 'react';
import { Box, SimpleGrid, Text, Stat, StatLabel, StatNumber, StatHelpText, Icon } from '@chakra-ui/react';
import { FaListAlt, FaQuestionCircle, FaTags, FaUsers } from 'react-icons/fa';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../hooks/useStore';
import { IconType } from 'react-icons';

// Define the interface for StatCard props
type StatCardProps = {
  title: string;
  value: number;
  icon: IconType;
  helpText: string;
};

const DashboardHomeScreen = () => {
  const { problemStore, multipleChoiceStore } = useStore();
  const [totalProblems, setTotalProblems] = useState(0);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [totalTags, setTotalTags] = useState(0);
  const [totalCategories, setTotalCategories] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      await problemStore.fetchProblems();
      await problemStore.fetchCategories();
      await problemStore.fetchTags();
      await multipleChoiceStore.fetchMultipleChoiceQuestions();

      setTotalProblems(problemStore.problems.length);
      setTotalQuestions(multipleChoiceStore.questions.length);
      setTotalTags(problemStore.tags.length);
      setTotalCategories(problemStore.categories.length);
    };

    fetchData();
  }, [problemStore, multipleChoiceStore]);

  // Update StatCard component with typed props
  const StatCard: React.FC<StatCardProps> = ({ title, value, icon, helpText }) => (
    <Box p={5} shadow={'md'} borderRadius="lg" bg={'white'}>
      <Stat>
        <StatLabel fontSize="xl" fontWeight="medium">
          {title}
        </StatLabel>
        <StatNumber fontSize="4xl" fontWeight="bold">
          {value}
        </StatNumber>
        <StatHelpText>
          <Icon as={icon} mr={2} />
          {helpText}
        </StatHelpText>
      </Stat>
    </Box>
  );

  return (
    <Box p={8}>
      <Text fontSize="3xl" fontWeight="bold" mb={8}>
        Dashboard Overview
      </Text>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={8}>
        <StatCard title="Problems" value={totalProblems} icon={FaListAlt} helpText="Coding problems" />
        <StatCard title="Multiple Choice" value={totalQuestions} icon={FaQuestionCircle} helpText="Quiz questions" />
        <StatCard title="Tags" value={totalTags} icon={FaTags} helpText="Problem tags" />
        <StatCard title="Categories" value={totalCategories} icon={FaUsers} helpText="Problem categories" />
      </SimpleGrid>
    </Box>
  );
};

export default observer(DashboardHomeScreen);
