export const Routes = {
  Home: '/',
  Login: '/auth/login',
  Dashboard: '/dashboard',
  DashboardHome: '/dashboard/home',
  DashboardProblems: '/dashboard/problems',
  DashboardProblemsCreate: '/dashboard/problems/create',
  DashboardProblemsEdit: '/dashboard/problems/edit/:id',
  DashboardMultipleChoice: '/dashboard/multiple-choice',
  DashboardMultipleChoiceEdit: '/dashboard/multiple-choice/edit/:id',
  DashboardMultipleChoiceCreate: '/dashboard/multiple-choice/create',
  DashboardCategories: '/dashboard/categories',
  DashboardCreateCategory: '/dashboard/categories/create',
  DashboardTags: '/dashboard/tags',
  DashboardVideoCourses: '/dashboard/video-courses',
  DashboardVideoCoursesCreate: '/dashboard/video-courses/create',
  DashboardVideoCoursesEdit: '/dashboard/video-courses/edit/:id',
};
