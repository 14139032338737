import React, { useState } from 'react';
import {
  Box,
  VStack,
  Icon,
  Text,
  Link,
  Button,
  IconButton,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  useDisclosure,
  Img,
} from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { MdLogout, MdMenu } from 'react-icons/md';
import { observer } from 'mobx-react-lite';
import { useAuth0 } from '@auth0/auth0-react';

import LogoLight from '../../assets/images/logo_light.svg';
import { CollapsibleLink } from './CollapsibleLink';
import { sidebarRoutes } from '../../constants/sidebarRoutes';
import { SidebarItem } from '../../constants/types';

const SideNavigator = () => {
  const [expandedRoute, setExpandedRoute] = useState<string | null>(null);
  const { logout } = useAuth0();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const location = useLocation();

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  const handleMainRouteClick = (path: string) => {
    if (expandedRoute === path) {
      setExpandedRoute(null);
    } else {
      setExpandedRoute(path);
    }
  };

  const isActive = (path: string) => location.pathname === path;

  const isChildActive = (route: SidebarItem) => {
    if (route.subRoutes) {
      return Object.values(route.subRoutes).some((subRoute) => isActive(subRoute.path));
    }
    return false;
  };

  const SidebarContent = () => (
    <VStack align="start" spacing={4} width="100%">
      {Object.values(sidebarRoutes).map((route: SidebarItem) => {
        if (route.subRoutes) {
          return (
            <CollapsibleLink
              key={route.path}
              route={route}
              expanded={expandedRoute === route.path || isChildActive(route)}
              onRouteClick={handleMainRouteClick}
              isActive={isActive}
            />
          );
        }

        return (
          <Link
            key={route.path}
            href={route.path}
            p={2}
            w={'100%'}
            display={'flex'}
            borderRadius={8}
            textDecoration={'none'}
            alignItems={'center'}>
            <Icon color={isActive(route.path) ? 'white' : 'primary.200'} as={route.icon} />
            <Text
              ml={4}
              alignSelf="center"
              fontWeight={isActive(route.path) ? 'bold' : 'regular'}
              color={isActive(route.path) ? 'white' : 'primary.200'}>
              {route.label}
            </Text>
          </Link>
        );
      })}
    </VStack>
  );

  return (
    <Box
      position="fixed"
      left={'-264px'}
      top={0}
      bottom={0}
      width="264px"
      borderRight="1px"
      borderRightColor={'primary.800'}
      overflowY="auto">
      <IconButton
        aria-label="Open menu"
        icon={<MdMenu />}
        onClick={onOpen}
        display={{ base: 'flex', md: 'none' }}
        position="fixed"
        top={4}
        left={4}
        zIndex={1002}
      />

      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent maxWidth="75%">
          <DrawerBody p={0}>
            <Box height="100vh" width="100%" bg={'primary.950'} display="flex" flexDir="column" p={4}>
              {/* <Text ml={3} mb={8} fontSize="2xl" fontWeight="bold">
                WeMentor
              </Text> */}
              <Box px={4} py={8}>
                <Img w="100%" src={LogoLight} alt="logo" />
              </Box>
              <SidebarContent />
              <Button colorScheme="red" onClick={handleLogout} mt="auto" mb={8}>
                <MdLogout />
                <Text ml={3}>Logout</Text>
              </Button>
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      <Box
        p={4}
        left={0}
        top={0}
        width="250px"
        height="100vh"
        position="fixed"
        display={{ base: 'none', md: 'flex' }}
        flexDir="column"
        bg={'primary.950'}
        zIndex={1000}>
        <Box px={4} py={8}>
          <Img w="100%" src={LogoLight} alt="logo" />
        </Box>
        <SidebarContent />
        <Button colorScheme="red" onClick={handleLogout} mt="auto" mb={8}>
          <MdLogout />
          <Text ml={3}>Logout</Text>
        </Button>
      </Box>
    </Box>
  );
};

export default observer(SideNavigator);
