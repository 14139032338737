import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import {
  Box,
  Text,
  VStack,
  Spinner,
  Divider,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useBreakpointValue,
  Collapse,
  IconButton,
  Button,
  useDisclosure,
  Flex,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon, AddIcon } from '@chakra-ui/icons';
import { useStore } from '../../hooks/useStore';
import CreateSectionDrawer from '../../components/courses/CreateSectionDrawer';
import CreateLectureDrawer from '../../components/courses/CreateLectureDrawer';

const DashboardCourseEditScreen = () => {
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(true);
  const [expandedSections, setExpandedSections] = useState<number[]>([]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    courseStore: { getCourseById, currentCourse },
    problemStore: { fetchProblems },
  } = useStore();

  const [lectureDrawerOpen, setLectureDrawerOpen] = useState(false);
  const [selectedSectionId, setSelectedSectionId] = useState<number | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        setLoading(true);
        try {
          await getCourseById(parseInt(id));
          await fetchProblems();
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [id, getCourseById, fetchProblems]);

  const tableSize = useBreakpointValue({ base: 'sm', md: 'md' });

  const toggleSection = (sectionId: number) => {
    setExpandedSections((prev) =>
      prev.includes(sectionId) ? prev.filter((id) => id !== sectionId) : [...prev, sectionId]
    );
  };

  const openLectureDrawer = (sectionId: number) => {
    setSelectedSectionId(sectionId);
    setLectureDrawerOpen(true);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Spinner size="xl" />
      </Box>
    );
  }

  if (!currentCourse) {
    return <Text>Course not found</Text>;
  }

  return (
    <Box p={8}>
      <Text fontSize={['xl', '2xl']} fontWeight="bold" mt={12} mb={4}>
        Edit Course: {currentCourse.title}
      </Text>
      <Divider mb={4} />
      <VStack align="start" spacing={4}>
        <Text>
          <strong>ID:</strong> {currentCourse.id}
        </Text>
        <Text>
          <strong>Author:</strong> {currentCourse.author?.email}
        </Text>
        <Text>
          <strong>Description:</strong> {currentCourse.description}
        </Text>
        <Text>
          <strong>Intro Video URL:</strong> {currentCourse.introVideoUrl}
        </Text>
        <Divider />
        <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
          <Text fontSize="lg" fontWeight="bold">
            Sections
          </Text>
          <Button leftIcon={<AddIcon />} colorScheme="primary" onClick={onOpen}>
            Add Section
          </Button>
        </Box>
        <Box overflowX="auto" width="100%">
          <Table variant="simple" size={tableSize}>
            <Thead>
              <Tr>
                <Th></Th>
                <Th>ID</Th>
                <Th>Title</Th>
                <Th>Description</Th>
                <Th>Problems</Th>
                <Th>Lectures</Th>
              </Tr>
            </Thead>
            <Tbody>
              {currentCourse.sections?.map((section) => (
                <React.Fragment key={section.id}>
                  <Tr>
                    <Td>
                      <IconButton
                        aria-label="Expand section"
                        icon={expandedSections.includes(section.id) ? <ChevronUpIcon /> : <ChevronDownIcon />}
                        onClick={() => toggleSection(section.id)}
                        size="sm"
                      />
                    </Td>
                    <Td>{section.id}</Td>
                    <Td>{section.title}</Td>
                    <Td>{section.description}</Td>
                    <Td>{section.problems?.length}</Td>
                    <Td>{section.lectures?.length}</Td>
                  </Tr>
                  <Tr>
                    <Td colSpan={6} p={0}>
                      <Collapse in={expandedSections.includes(section.id)}>
                        <Box p={4} bg="gray.50">
                          <Flex justifyContent="space-between" alignItems="center" mb={2}>
                            <Text fontWeight="bold">Lectures</Text>
                            <Button size="sm" leftIcon={<AddIcon />} onClick={() => openLectureDrawer(section.id)}>
                              Add Lecture
                            </Button>
                          </Flex>
                          {section.lectures?.length > 0 ? (
                            <Table variant="simple" size="sm">
                              <Thead>
                                <Tr>
                                  <Th>ID</Th>
                                  <Th>Title</Th>
                                  <Th>Video URL</Th>
                                </Tr>
                              </Thead>
                              <Tbody>
                                {section.lectures.map((lecture) => (
                                  <Tr key={lecture.id}>
                                    <Td>{lecture.id}</Td>
                                    <Td>{lecture.title}</Td>
                                    <Td>{lecture.videoUrl}</Td>
                                  </Tr>
                                ))}
                              </Tbody>
                            </Table>
                          ) : (
                            <Text>No lectures found for this section.</Text>
                          )}
                        </Box>
                      </Collapse>
                    </Td>
                  </Tr>
                </React.Fragment>
              ))}
            </Tbody>
          </Table>
        </Box>
      </VStack>

      <CreateSectionDrawer isOpen={isOpen} onClose={onClose} courseId={currentCourse.id} />
      <CreateLectureDrawer
        isOpen={lectureDrawerOpen}
        onClose={() => setLectureDrawerOpen(false)}
        sectionId={selectedSectionId || 0}
      />
    </Box>
  );
};

export default observer(DashboardCourseEditScreen);
