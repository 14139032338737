import React, { useEffect, useState } from 'react';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Button,
  useBreakpointValue,
  VStack,
  Flex,
  Tag,
  IconButton,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../hooks/useStore';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../router/routes';

const DashboardProblemScreen = () => {
  const { problemStore } = useStore();
  const { problems, fetchProblems, deleteProblem } = problemStore;
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [problemToDelete, setProblemToDelete] = useState<number | null>(null);

  useEffect(() => {
    fetchProblems({
      sorting: {
        property: 'createdAt',
        direction: 'desc',
      },
    });
  }, [fetchProblems]);

  const handleRowClick = (problemId: number) => {
    navigate(Routes.DashboardProblemsEdit.replace(':id', problemId.toString()));
  };

  const handleDeleteClick = (e: React.MouseEvent, problemId: number) => {
    e.stopPropagation();
    setProblemToDelete(problemId);
    onOpen();
  };

  const confirmDelete = () => {
    if (problemToDelete) {
      deleteProblem(problemToDelete);
      onClose();
    }
  };

  // Responsive layout adjustments
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Box p={4}>
      <Flex direction="column" align="stretch" maxWidth="1200px" mx="auto">
        <Text fontSize={['xl', '2xl']} fontWeight="bold" mt={12} mb={4}>
          Problems
        </Text>
        <Button
          colorScheme="primary"
          mb={4}
          onClick={() => navigate(Routes.DashboardProblemsCreate)}
          alignSelf="flex-start">
          Create New Problem
        </Button>
        {isMobile ? (
          <VStack spacing={4} align="stretch">
            {problems.map((problem) => (
              <Box
                key={problem.id}
                p={4}
                borderWidth={1}
                borderRadius="md"
                onClick={() => handleRowClick(problem.id)}
                cursor="pointer"
                _hover={{ bg: 'gray.100' }}>
                <Flex justify="space-between" align="center" mb={2}>
                  <Text fontWeight="bold">{problem.title}</Text>
                  <IconButton
                    aria-label="Delete problem"
                    icon={<DeleteIcon />}
                    size="sm"
                    onClick={(e) => handleDeleteClick(e, problem.id)}
                    colorScheme="red"
                    color="white"
                  />
                </Flex>
                <Text>ID: {problem.id}</Text>
                <Text>Difficulty: {problem.difficulty}</Text>
                <Text>Category: {problem.category?.title || 'N/A'}</Text>
                <Text>Tests: {problem.tests?.length || 0}</Text>
                <HStack mt={2}>
                  {problem.tags?.map((tag) => (
                    <Tag key={tag.id} size="sm">
                      {tag.name}
                    </Tag>
                  ))}
                </HStack>
              </Box>
            ))}
          </VStack>
        ) : (
          <Box overflowX="auto">
            <Table variant="striped" colorScheme="gray">
              <Thead>
                <Tr>
                  <Th>ID</Th>
                  <Th>Title</Th>
                  <Th>Difficulty</Th>
                  <Th>Category</Th>
                  <Th>Tests</Th>
                  <Th>Tags</Th>
                  <Th>Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
                {problems.map((problem) => (
                  <Tr
                    key={problem.id}
                    onClick={() => handleRowClick(problem.id)}
                    cursor="pointer"
                    _hover={{ bg: 'gray.100' }}>
                    <Td>{problem.id}</Td>
                    <Td>{problem.title}</Td>
                    <Td>{problem.difficulty}</Td>
                    <Td>{problem.category?.title || 'N/A'}</Td>
                    <Td>{problem.tests?.length || 0}</Td>
                    <Td>
                      <HStack>
                        {problem.tags?.map((tag) => (
                          <Tag key={tag.id} size="sm" colorScheme="primary">
                            {tag.name}
                          </Tag>
                        ))}
                      </HStack>
                    </Td>
                    <Td>
                      <IconButton
                        aria-label="Delete problem"
                        icon={<DeleteIcon />}
                        size="sm"
                        onClick={(e) => handleDeleteClick(e, problem.id)}
                        colorScheme="red"
                        color="white"
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        )}
      </Flex>

      {/* Centered Confirmation Modal */}
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Deletion</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Are you sure you want to delete this problem?</ModalBody>
          <ModalFooter>
            <Button colorScheme="gray" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="red" onClick={confirmDelete}>
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default observer(DashboardProblemScreen);
