export const endpoints = {
  course: {
    base: 'course',
    byId: (id: number) => `course/${id}`,
    addSection: (id: number) => `course/${id}/section`,
    addLecture: (courseId: number, sectionId: number) => `course/${courseId}/section/${sectionId}/lecture`,
  },
  problem: {
    base: 'problem',
    solution: (problemId: number) => `problem/${problemId}/solution`,
    favorite: 'problem/favorite',
    category: 'problem/category',
    tag: 'problem/tag',
    updateSolution: (problemId: number, solutionId: number) => `problem/${problemId}/solution/${solutionId} `,
  },
  multipleChoice: {
    base: 'multiple-choice',
    check: 'multiple-choice/check',
    byId: (id: number) => `multiple-choice/${id}`,
  },
  compiler: {
    base: 'compiler',
    submission: 'compiler/submission',
    solution: 'compiler/solution',
  },
  user: {
    base: 'user',
    byEmail: (email: string) => `user/email/${email}`,
  },
};
