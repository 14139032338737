import React, { useEffect } from 'react';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Button,
  useBreakpointValue,
  Divider,
  useDisclosure,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../hooks/useStore';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../router/routes';
import CreateCourseDrawer from '../../components/courses/CreateCourseDrawer';

const DashboardVideoCoursesScreen = () => {
  const {
    courseStore: { fetchCourses, courses },
  } = useStore();

  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    fetchCourses();
  }, [fetchCourses]);

  const handleRowClick = (courseId: number) => {
    navigate(Routes.DashboardVideoCoursesEdit.replace(':id', courseId.toString()));
  };

  // Use responsive layout for table columns
  const tableLayout = useBreakpointValue({
    base: 'stack',
    md: 'fixed',
  });

  // Adjust visible columns based on screen size
  const showAuthor = useBreakpointValue({ base: false, md: true });
  const showSections = useBreakpointValue({ base: false, lg: true });

  return (
    <Box overflowX="auto">
      <Text fontSize={['xl', '2xl']} fontWeight="bold" mt={12} mb={4}>
        Video Courses
      </Text>
      <Divider />
      <Button colorScheme="primary" mb={4} mt={4} onClick={onOpen} alignSelf="flex-start">
        Create New Course
      </Button>
      <Table variant="striped" colorScheme="gray" layout={tableLayout}>
        <Thead>
          <Tr>
            <Th>ID</Th>
            <Th>Title</Th>
            {showAuthor && <Th>Author</Th>}
            {showSections && <Th>Sections</Th>}
          </Tr>
        </Thead>
        <Tbody>
          {courses?.map((course) => (
            <Tr key={course.id} onClick={() => handleRowClick(course.id)} cursor="pointer" _hover={{ bg: 'gray.100' }}>
              <Td>{course.id}</Td>
              <Td>{course.title}</Td>
              {showAuthor && <Td>{course.author?.email}</Td>}
              {showSections && <Td>{course.sections?.length}</Td>}
            </Tr>
          ))}
        </Tbody>
      </Table>
      <CreateCourseDrawer isOpen={isOpen} onClose={onClose} />
    </Box>
  );
};

export default observer(DashboardVideoCoursesScreen);
