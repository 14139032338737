import { BaseApi } from '.';
import { ICourse } from '../models/course';
import { endpoints } from './endpoints';

export class CourseApi extends BaseApi<ICourse> {
  constructor() {
    super(endpoints.course.base);
  }

  async createCourse(data: FormData) {
    const res = await this.axios.post(endpoints.course.base, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      timeout: 10000,
    });

    console.log('RES is ', res);

    return res.data;
  }
}
