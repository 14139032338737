import { makeAutoObservable, runInAction } from 'mobx';
import { IProblem, IProblemSolution, IProblemTest, ProblemCategory } from '../models/problem';
import { ProblemApi } from '../api/ProblemApi';
import { CreateCategoryDto, CreateProblemDto, Filter, Sorting } from '../constants/types';
import { ITag } from '../models/tag';
import { buildFilterQueryParams } from '../utils/api';

export class ProblemStore {
  private api: ProblemApi;

  loading: boolean = false;
  solutionLoading: boolean = false;

  problems: IProblem[] = [];
  favoriteProblems: IProblem[] = [];
  currentProblem: IProblem | undefined = undefined;
  currentProblemSolution: IProblemSolution | undefined = undefined;
  problemSolutions: IProblemSolution[] = [];
  userTests: Omit<IProblemTest, 'id' | 'problemId'>[] = [{ input: '', output: '' }];
  categories: ProblemCategory[] = [];
  tags: ITag[] = [];

  // UI
  problemTabIndex: number = 0;

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
    this.api = new ProblemApi();
  }

  setProblems(problems: IProblem[]) {
    this.problems = problems;
  }

  setTags(tags: ITag[]) {
    this.tags = tags;
  }

  setProbeTabIndex(index: number) {
    this.problemTabIndex = index;
  }

  setCategories(categories: ProblemCategory[]) {
    this.categories = categories;
  }

  setLoading(loading: boolean) {
    this.loading = loading;
  }

  setSolutionLoading(loading: boolean) {
    this.solutionLoading = loading;
  }

  setCurrentProblem(problem: IProblem) {
    this.currentProblem = problem;
  }

  setFavoriteProblems(problems: IProblem[]) {
    this.favoriteProblems = problems;
  }

  setCurrentProblemSolution(solution: IProblemSolution) {
    this.currentProblemSolution = solution;
  }

  setProblemSolutions(solutions: IProblemSolution[]) {
    this.problemSolutions = solutions;
  }

  async createCategory(data: CreateCategoryDto) {
    try {
      this.setLoading(true);
      const res = await this.api.createCategory(data);
      this.categories.push(res.data as unknown as ProblemCategory);
      return res;
    } catch (error) {
      console.error('Error creating category:', error);
      throw error;
    } finally {
      this.setLoading(false);
    }
  }

  async createProblem(problem: CreateProblemDto) {
    try {
      this.setLoading(true);
      const createdProblem = await this.api.create(problem);
      this.problems.push(createdProblem);
      return createdProblem;
    } catch (error) {
      console.error('Error creating problem:', error);
      throw error;
    } finally {
      this.setLoading(false);
    }
  }

  async fetchTags() {
    try {
      const res = await this.api.fetchTags();
      this.setTags(res.items as unknown as ITag[]);
    } catch (error) {
      console.error(error);
    }
  }

  async createTag(name: string) {
    try {
      this.setLoading(true);
      const res = await this.api.createTag(name);
      this.tags.push(res.data as unknown as ITag);
      return res;
    } catch (error) {
      console.error('Error creating tag:', error);
      throw error;
    } finally {
      this.setLoading(false);
    }
  }

  async fetchCategories() {
    try {
      const res = await this.api.fetchCategories();
      this.setCategories(res.items);
    } catch (error) {
      console.error(error);
    }
  }

  async fetchProblems({ filters, sorting }: { filters?: Filter[]; sorting?: Sorting } = {}) {
    try {
      const res = await this.api.fetch({ params: buildFilterQueryParams(filters || [], sorting) });
      this.setProblems(res.items);
    } catch (error) {
      console.error(error);
    }
  }

  async fetchProblemSolutions(problemId: number) {
    try {
      const res = await this.api.fetchProblemSolutions(problemId, [{ property: 'userId', value: 1, rule: 'neq' }]);
      this.setProblemSolutions(res.items as unknown as IProblemSolution[]);
    } catch (error) {
      console.error(error);
    }
  }

  async fetchFavoriteProblems() {
    try {
      const res = await this.api.fetchFavoriteProblems();
      this.setFavoriteProblems(res.items);
    } catch (error) {
      console.error(error);
    }
  }

  async saveToFavorites(problemId: number) {
    try {
      await this.api.saveToFavorite(problemId);

      const index = this.favoriteProblems.findIndex((problem) => problem.id === problemId);

      if (index !== -1) {
        const copy = [...this.favoriteProblems];
        copy.splice(index, 1);

        this.setFavoriteProblems([...copy]);
      } else {
        this.setFavoriteProblems([...this.favoriteProblems, { id: problemId } as IProblem]);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async getAdminProblemSolution(problemId: number, languageId: number) {
    try {
      this.setSolutionLoading(true);
      const filters: Filter[] = [
        { property: 'problemId', value: problemId, rule: 'eq' },
        { property: 'languageId', value: languageId, rule: 'eq' },
        { property: 'userId', value: 1, rule: 'eq' }, // We need the admin user solution
      ];
      const response = await this.api.fetchProblemSolutions(problemId, filters);

      if (response.items.length > 0) {
        const solution = response.items[0] as unknown as IProblemSolution;
        this.setCurrentProblemSolution(solution);
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.setSolutionLoading(true);
    }
  }

  async getProblemById(id: number) {
    try {
      this.setLoading(true);
      const problem = await this.api.getById(id);
      this.setCurrentProblem(problem);
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => {
        this.setLoading(false);
      }, 1000);
    }
  }

  async updateProblem(id: number, problem: Partial<CreateProblemDto>) {
    try {
      this.setLoading(true);
      const updatedProblem = await this.api.patch(id, problem);
      const index = this.problems.findIndex((p) => p.id === id);
      if (index !== -1) {
        this.problems[index] = updatedProblem;
      }
      return updatedProblem;
    } catch (error) {
      console.error('Error updating problem:', error);
      throw error;
    } finally {
      this.setLoading(false);
    }
  }

  async updateProblemSolution(problemId: number, solutionId: number, solution: Partial<IProblemSolution>) {
    try {
      this.setSolutionLoading(true);

      const res = await this.api.updateProblemSolution(problemId, solutionId, solution);
      console.log('Res is ', res);
    } catch (error) {
      console.error('Error updating problem solution:', error);
      throw error;
    } finally {
      this.setSolutionLoading(false);
    }
  }

  async deleteProblem(problemId: number) {
    this.loading = true;
    try {
      await this.api.delete(problemId);
      runInAction(() => {
        this.problems = this.problems.filter((problem) => problem.id !== problemId);
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });
    }
  }
}
