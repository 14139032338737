import { Box, Collapse, HStack, Icon, Text, VStack, Link } from '@chakra-ui/react';

import { SidebarItem } from '../../constants/types';

export interface CollapsibleLinkProps {
  route: SidebarItem;
  expanded: boolean;
  onRouteClick: (path: string) => void;
  isActive: (path: string) => boolean;
}

export const CollapsibleLink: React.FC<CollapsibleLinkProps> = ({ route, expanded, onRouteClick, isActive }) => {
  const isMainRouteActive =
    isActive(route.path) ||
    (route.subRoutes && Object.values(route.subRoutes).some((subRoute) => isActive(subRoute.path)));

  return (
    <Box key={route.path} w="100%">
      <HStack
        p={2}
        w="100%"
        cursor="pointer"
        color={isMainRouteActive ? 'white' : 'primary.200'}
        borderRadius={8}
        mb={expanded ? 4 : 0}
        justifyContent="flex-start"
        _focus={{ boxShadow: 'none' }}
        onClick={() => onRouteClick(route.path)}>
        <Icon as={route.icon} />
        <Text ml={4}>{route.label}</Text>
      </HStack>
      <Collapse in={expanded} animateOpacity>
        <VStack pl={8} align="start" w="100%">
          {route.subRoutes &&
            Object.values(route.subRoutes).map((subRoute: SidebarItem) => (
              <Link
                key={subRoute.path}
                href={subRoute.path}
                style={{
                  textDecoration: 'none',
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center',
                }}
                p={2}
                borderRadius={8}>
                <Icon color={isActive(subRoute.path) ? 'white' : 'primary.200'} as={subRoute.icon} />
                <Text w="100%" ml={4} color={isActive(subRoute.path) ? 'white' : 'primary.200'}>
                  {subRoute.label}
                </Text>
              </Link>
            ))}
        </VStack>
      </Collapse>
    </Box>
  );
};
