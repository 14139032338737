export enum ProblemDifficulty {
  Easy,
  Medium,
  Hard,
  Expert,
}

/**
 * Exam subjects.
 */

export enum ExamSubjects {
  First = 1,
  Second,
  Third,
}

/**
 * CodeWaves exercise types.
 */
export enum ExerciseType {
  MultipleChoice = 1,
  MultiStep,
  Coding,
  AnswerVerification,
}

/**
 * CodeWaves exercise categories
 */
export enum ExerciseCategory {
  Arithmetic = 1,
  DataStructures = 2,
  Algorithms = 4,
  Greedy = 8,
  Sorting = 16,
  Searching = 32,
  DynamicProgramming = 64,
  Graphs = 128,
  Strings = 256,
  Complexity = 512,
  Backtracking = 1024,
  Matrices = 2048,
  Arrays = 4096,
  DivideAndConquer = 8192,
  Miscellaneous = 16384,
}

export enum PopoverEnum {
  NavbarPopover = 'navbarPopover',
}

export enum EditorMode {
  Algo,
  Playground,
}
