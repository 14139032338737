import axios, { AxiosInstance } from 'axios';

export class AxiosClient {
  private axios: AxiosInstance;
  private static axiosClient: AxiosClient;
  tokenGenerator: any;

  constructor() {
    this.axios = axios.create({
      baseURL: process.env.REACT_APP_BASE_API_URL,
      timeout: 5000,
    });

    this.axios.interceptors.request.use(
      async (config) => {
        const token = await this.getToken();

        if (token) {
          config.headers['Authorization'] = `Bearer ${token}`;
        }

        return config;
      },
      (error) => {
        console.error('Axios error', error);
      }
    );
  }

  setTokenGenerator(tokenGenerator: any) {
    this.tokenGenerator = tokenGenerator;
  }

  async getToken() {
    if (this.tokenGenerator) return this.tokenGenerator();
  }

  getAxios() {
    return this.axios;
  }

  public static getInstance(): AxiosClient {
    if (!AxiosClient.axiosClient) {
      AxiosClient.axiosClient = new AxiosClient();
    }
    return AxiosClient.axiosClient;
  }
}
