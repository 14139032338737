import React, { useState } from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import { useStore } from '../../hooks/useStore';
import { MultiSelect } from '../common/MultiSelect';

interface CreateSectionDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  courseId: number;
}

const CreateSectionDrawer: React.FC<CreateSectionDrawerProps> = ({ isOpen, onClose, courseId }) => {
  const [newSectionTitle, setNewSectionTitle] = useState('');
  const [newSectionDescription, setNewSectionDescription] = useState('');
  const [selectedProblems, setSelectedProblems] = useState<any[]>([]);
  const [cheatSheet, setCheatSheet] = useState('');
  const toast = useToast();

  const {
    courseStore: { addSection },
    problemStore: { problems },
  } = useStore();

  const handleAddSection = async () => {
    try {
      await addSection(courseId, {
        title: newSectionTitle,
        description: newSectionDescription,
        problemIds: selectedProblems.map((problem) => problem.value),
        cheatSheet: cheatSheet,
      });

      console.log('creating section with data', {
        title: newSectionTitle,
        description: newSectionDescription,
        problemIds: selectedProblems.map((problem) => problem.value),
        cheatSheet: cheatSheet,
      });

      onClose();
      resetForm();
      toast({
        title: 'Section added successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error adding section:', error);
      toast({
        title: 'Error adding section',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const resetForm = () => {
    setNewSectionTitle('');
    setNewSectionDescription('');
    setSelectedProblems([]);
    setCheatSheet('');
  };

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Add New Section</DrawerHeader>
        <DrawerBody>
          <VStack spacing={4}>
            <FormControl isRequired>
              <FormLabel>Title</FormLabel>
              <Input value={newSectionTitle} onChange={(e) => setNewSectionTitle(e.target.value)} />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Description</FormLabel>
              <Textarea value={newSectionDescription} onChange={(e) => setNewSectionDescription(e.target.value)} />
            </FormControl>
            <FormControl>
              <FormLabel>Associated Problems</FormLabel>
              <MultiSelect
                label="Problems"
                value={selectedProblems}
                onChange={(values) => setSelectedProblems(values)}
                options={problems.map((problem) => ({ label: problem.title, value: problem.id }))}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Cheat Sheet</FormLabel>
              <Textarea
                value={cheatSheet}
                onChange={(e) => setCheatSheet(e.target.value)}
                placeholder="Enter cheat sheet content"
              />
            </FormControl>
          </VStack>
        </DrawerBody>
        <DrawerFooter>
          <Button flex={1} colorScheme="primary" onClick={handleAddSection}>
            Add Section
          </Button>
          <Button flex={1} variant="outline" ml={3} onClick={onClose}>
            Cancel
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default CreateSectionDrawer;
