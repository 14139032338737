import { makeAutoObservable, runInAction } from 'mobx';
import { ICourse, ISection, ILecture } from '../models/course';

import { CourseApi } from '../api/CourseApi';
import { endpoints } from '../api/endpoints';
import { CreateSectionDto } from '../constants/types';

export class CourseStore {
  private readonly api: CourseApi;

  courses: ICourse[] = [];
  currentCourse: ICourse | null = null;
  loading: boolean = false;

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
    this.api = new CourseApi();
  }

  setLoading(loading: boolean) {
    this.loading = loading;
  }

  setCourses(courses: ICourse[]) {
    this.courses = courses;
  }

  setCurrentCourse(course: ICourse | null) {
    this.currentCourse = course;
  }

  async createCourse(data: FormData) {
    try {
      this.setLoading(true);
      const createdCourse = await this.api.createCourse(data);
      console.log(createdCourse);
      this.courses.push(createdCourse);
      return createdCourse;
    } catch (error) {
      console.error('Error creating course:', error);
      throw error;
    } finally {
      this.setLoading(false);
    }
  }

  async fetchCourses() {
    try {
      this.setLoading(true);
      const response = await this.api.fetch();
      this.setCourses(response.items);
    } catch (error) {
      console.error('Error fetching courses:', error);
    } finally {
      this.setLoading(false);
    }
  }

  async getCourseById(id: number) {
    try {
      this.setLoading(true);
      const course = await this.api.getById(id);
      this.setCurrentCourse(course);
    } catch (error) {
      console.error('Error fetching course by id:', error);
    } finally {
      this.setLoading(false);
    }
  }

  async updateCourse(id: number, courseData: Partial<ICourse>) {
    try {
      this.setLoading(true);
      const updatedCourse = await this.api.patch(id, courseData);
      runInAction(() => {
        const index = this.courses.findIndex((course) => course.id === id);
        if (index !== -1) {
          this.courses[index] = updatedCourse;
        }
        if (this.currentCourse && this.currentCourse.id === id) {
          this.currentCourse = updatedCourse;
        }
      });
      return updatedCourse;
    } catch (error) {
      console.error('Error updating course:', error);
      throw error;
    } finally {
      this.setLoading(false);
    }
  }

  async deleteCourse(id: number) {
    try {
      this.setLoading(true);
      await this.api.delete(id);
      runInAction(() => {
        this.courses = this.courses.filter((course) => course.id !== id);
        if (this.currentCourse && this.currentCourse.id === id) {
          this.currentCourse = null;
        }
      });
    } catch (error) {
      console.error('Error deleting course:', error);
      throw error;
    } finally {
      this.setLoading(false);
    }
  }

  async addSection(courseId: number, sectionData: CreateSectionDto) {
    try {
      this.setLoading(true);
      const res = await this.api.post(sectionData, `${endpoints.course.base}/${courseId}/section`);

      if (this.currentCourse) {
        this.setCurrentCourse({
          ...this.currentCourse,
          sections: [...this.currentCourse?.sections, res.data as unknown as ISection],
        });
      }
    } catch (error) {
      console.error('Error adding section:', error);
      throw error;
    } finally {
      this.setLoading(false);
    }
  }

  async addLecture(courseId: number, sectionId: number, lectureData: Partial<ILecture>) {
    try {
      this.setLoading(true);
      const sectionLectures = this.currentCourse?.sections.find((section) => section.id === sectionId)?.lectures;
      const res = await this.api.post(
        { ...lectureData, order: sectionLectures?.length || 0 + 1 },
        endpoints.course.addLecture(courseId, sectionId)
      );

      if (this.currentCourse) {
        const updatedSections = this.currentCourse.sections.map((section) => {
          if (section.id === sectionId) {
            return { ...section, lectures: [...section.lectures, res.data as ILecture] };
          }
          return section;
        });
        this.setCurrentCourse({ ...this.currentCourse, sections: updatedSections });
      }
    } catch (error) {
      console.error('Error adding lecture:', error);
      throw error;
    } finally {
      this.setLoading(false);
    }
  }
}
