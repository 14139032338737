import React, { useState } from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import { useStore } from '../../hooks/useStore';

interface CreateLectureDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  sectionId: number;
}

const CreateLectureDrawer: React.FC<CreateLectureDrawerProps> = ({ isOpen, onClose, sectionId }) => {
  const [title, setTitle] = useState('');
  const [videoUrl, setVideoUrl] = useState('');
  const [codeExample, setCodeExample] = useState('');
  const [codeExampleLanguageId, setCodeExampleLanguageId] = useState<number>(0);
  const toast = useToast();

  const {
    courseStore: { addLecture, currentCourse },
  } = useStore();

  const handleAddLecture = async () => {
    try {
      if (currentCourse) {
        await addLecture(currentCourse.id, sectionId, {
          title,
          videoUrl,
          codeExample,
          codeExampleLanguageId,
        });
      }

      onClose();
      resetForm();
      toast({
        title: 'Lecture added successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error adding lecture:', error);
      toast({
        title: 'Error adding lecture',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const resetForm = () => {
    setTitle('');
    setVideoUrl('');
    setCodeExample('');
    setCodeExampleLanguageId(0);
  };

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Add New Lecture</DrawerHeader>
        <DrawerBody>
          <VStack spacing={4}>
            <FormControl isRequired>
              <FormLabel>Title</FormLabel>
              <Input value={title} onChange={(e) => setTitle(e.target.value)} />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Video URL</FormLabel>
              <Input value={videoUrl} onChange={(e) => setVideoUrl(e.target.value)} />
            </FormControl>
            <FormControl>
              <FormLabel>Code Example</FormLabel>
              <Textarea
                value={codeExample}
                onChange={(e) => setCodeExample(e.target.value)}
                placeholder="Enter code example (optional)"
              />
            </FormControl>
            <FormControl>
              <FormLabel>Code Example Language ID</FormLabel>
              <Input
                type="number"
                value={codeExampleLanguageId}
                onChange={(e) => setCodeExampleLanguageId(Number(e.target.value))}
                placeholder="Enter language ID (optional)"
              />
            </FormControl>
          </VStack>
        </DrawerBody>
        <DrawerFooter>
          <Button flex={1} colorScheme="primary" onClick={handleAddLecture}>
            Add Lecture
          </Button>
          <Button flex={1} variant="outline" ml={3} onClick={onClose}>
            Cancel
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default CreateLectureDrawer;
