import { makeAutoObservable } from 'mobx';
import { MultipleChoiceApi } from '../api/MultipleChoiceApi';
import { IMultipleChoice } from '../models/multiple-choice';
import { endpoints } from '../api/endpoints';
import { CreateMultipleChoiceDto } from '../constants/types';

export class MultipleChoiceStore {
  private readonly api: MultipleChoiceApi;

  questions: IMultipleChoice[] = [];
  currentMultipleChoice: IMultipleChoice | undefined = undefined;
  loading: boolean = false;

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
    this.api = new MultipleChoiceApi();
  }

  setLoading(loading: boolean) {
    this.loading = loading;
  }

  setCurentMultipleChoice(multipleChoice: IMultipleChoice) {
    this.currentMultipleChoice = multipleChoice;
  }

  setQuestions(questions: IMultipleChoice[]) {
    this.questions = questions;
  }

  async fetchMultipleChoiceQuestions() {
    try {
      const res = await this.api.fetch({});
      this.setQuestions(res.items);
    } catch (error) {
      console.error(error);
    }
  }

  async getMultipleChoiceById(id: number) {
    try {
      await this.api.fetch({}, endpoints.multipleChoice.byId(id));
    } catch (error) {
      console.error(error);
    }
  }

  async checkAnswer(answerId: number, questionId: number) {
    try {
      return this.api.checkAnswer(questionId, answerId);
    } catch (error) {
      console.error(error);
    }
  }

  async createMultipleChoice(multipleChoice: CreateMultipleChoiceDto) {
    try {
      this.setLoading(true);
      const createdMultipleChoice = await this.api.create(multipleChoice);
      this.questions.push(createdMultipleChoice);
      return createdMultipleChoice;
    } catch (error) {
      console.error('Error creating multiple choice question:', error);
      throw error;
    } finally {
      this.setLoading(false);
    }
  }
}
