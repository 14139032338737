import { BaseApi } from '.';
import { CreateCategoryDto, Filter } from '../constants/types';
import { IProblem, IProblemSolution } from '../models/problem';
import { buildFilterQueryParams } from '../utils/api';
import { endpoints } from './endpoints';

export class ProblemApi extends BaseApi<IProblem> {
  constructor() {
    super(endpoints.problem.base);
  }

  fetchProblemSolutions(problemId: number, filters: Filter[]) {
    const query = buildFilterQueryParams(filters);
    return this.fetch({ params: query }, endpoints.problem.solution(problemId));
  }

  fetchFavoriteProblems() {
    return this.fetch({}, endpoints.problem.favorite);
  }

  saveToFavorite(problemId: number) {
    return this.post({ problemId }, endpoints.problem.favorite);
  }

  fetchCategories() {
    return this.fetch({}, endpoints.problem.category);
  }

  fetchTags() {
    return this.fetch({}, endpoints.problem.tag);
  }

  createTag(name: string) {
    return this.post({ name }, endpoints.problem.tag);
  }

  createCategory(data: CreateCategoryDto) {
    return this.post(data, endpoints.problem.category);
  }

  updateProblemSolution(problemId: number, solutionId: number, solution: Partial<IProblemSolution>) {
    return this.axios.patch(endpoints.problem.updateSolution(problemId, solutionId), solution);
  }
}
