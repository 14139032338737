import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../hooks/useStore';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  VStack,
  Text,
  useToast,
  HStack,
  IconButton,
  Checkbox,
} from '@chakra-ui/react';
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';

import { ProblemDifficulty } from '../../constants/enums';
import { IAnswer } from '../../models/multiple-choice';

const inputStyles = {
  border: 'none',
  boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
  bg: 'white',
};

const CreateMultipleChoiceScreen = () => {
  const { multipleChoiceStore } = useStore();

  const toast = useToast();

  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [difficulty, setDifficulty] = useState<ProblemDifficulty>(ProblemDifficulty.Easy);
  const [answers, setAnswers] = useState<Partial<IAnswer>[]>([{ text: '', isCorrect: false }]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      await multipleChoiceStore.createMultipleChoice({
        title,
        text,
        difficulty,
        answers,
      });
      toast({
        title: 'Multiple choice question created',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
      // Clear out the fields
      setTitle('');
      setText('');
      setDifficulty(ProblemDifficulty.Easy);
      setAnswers([{ text: '', isCorrect: false }]);
    } catch (error) {
      toast({
        title: 'Error creating multiple choice question',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  const handleAddAnswer = () => {
    setAnswers([...answers, { text: '', isCorrect: false }]);
  };

  const handleRemoveAnswer = (index: number) => {
    setAnswers(answers.filter((_, i) => i !== index));
  };

  const handleAnswerChange = (index: number, field: 'text' | 'isCorrect', value: string | boolean) => {
    const newAnswers = [...answers];
    newAnswers[index] = { ...newAnswers[index], [field]: value };
    setAnswers(newAnswers);
  };

  return (
    <Box maxWidth={['100%', '100%', '800px']} margin="auto" padding={[4, 6, 8]}>
      <Text fontSize={['xl', '2xl']} fontWeight="bold" mt={12} mb={4}>
        Create New Multiple Choice Question
      </Text>
      <form onSubmit={handleSubmit}>
        <VStack spacing={4} align="stretch">
          <FormControl isRequired>
            <FormLabel>Title</FormLabel>
            <Input value={title} onChange={(e) => setTitle(e.target.value)} {...inputStyles} />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Text</FormLabel>
            <Textarea value={text} onChange={(e) => setText(e.target.value)} {...inputStyles} />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Difficulty</FormLabel>
            <Select
              value={difficulty}
              onChange={(e) => setDifficulty(Number(e.target.value))}
              size={['sm', 'md']}
              {...inputStyles}>
              {Object.entries(ProblemDifficulty)
                .filter(([key]) => isNaN(Number(key)))
                .map(([key, value]) => (
                  <option key={key} value={value}>
                    {key}
                  </option>
                ))}
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>Answers</FormLabel>
            {answers.map((answer, index) => (
              <HStack key={index} mb={2} spacing={2} alignItems="center">
                <Input
                  placeholder="Answer text"
                  value={answer.text}
                  onChange={(e) => handleAnswerChange(index, 'text', e.target.value)}
                  size={['sm', 'md']}
                  flex={1}
                  {...inputStyles}
                />
                <Checkbox
                  isChecked={answer.isCorrect}
                  onChange={(e) => handleAnswerChange(index, 'isCorrect', e.target.checked)}
                  size={['sm', 'md']}>
                  Correct
                </Checkbox>
                <IconButton
                  aria-label="Remove answer"
                  icon={<DeleteIcon />}
                  onClick={() => handleRemoveAnswer(index)}
                  size={['sm', 'md']}
                />
              </HStack>
            ))}
            <Button leftIcon={<AddIcon />} onClick={handleAddAnswer} size={['sm', 'md']} mt={2}>
              Add Answer
            </Button>
          </FormControl>
          <Button type="submit" colorScheme="primary" isLoading={multipleChoiceStore.loading} size={['sm', 'md']}>
            Create
          </Button>
        </VStack>
      </form>
    </Box>
  );
};

export default observer(CreateMultipleChoiceScreen);
