import React, { useEffect, useState } from 'react';
import { Box, useBreakpointValue } from '@chakra-ui/react';
import { Outlet } from 'react-router';
import SideNavbar from '../../components/navigation/SideNavbar';
import { useAuth0 } from '@auth0/auth0-react';
import { AxiosClient } from '../../api/api';

const DashboardOutlet = () => {
  const [loading, setLoading] = useState(true);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const isMobile = useBreakpointValue({ base: true, md: false });

  /**
   * Make sure to not render anything before the token generator
   * fucntion is assinged in the AxiosCLient singleton.
   */
  useEffect(() => {
    setLoading(true);
    if (!isAuthenticated) return;
    AxiosClient.getInstance().setTokenGenerator(getAccessTokenSilently);
    setLoading(false);
  }, [getAccessTokenSilently, isAuthenticated]);

  if (loading) return null;

  return (
    <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} minHeight="100vh">
      <SideNavbar />
      <Box
        bg={'gray.300'}
        flex={1}
        marginLeft={isMobile ? 0 : '264px'}
        transition="margin-left 0.3s"
        p={4}
        overflowY="auto">
        <Outlet />
      </Box>
    </Box>
  );
};

export default DashboardOutlet;
