import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { AxiosClient } from './api';

export class BaseApi<T> {
  protected axios: AxiosInstance;
  protected endpoint: string;

  constructor(endpoint: string) {
    this.endpoint = endpoint;
    this.axios = AxiosClient.getInstance().getAxios();
  }

  async post<T>(body: any, endpoint?: string): Promise<AxiosResponse<T>> {
    const effectiveEndpoint = endpoint || this.endpoint;
    return this.axios.post(`${effectiveEndpoint}`, body);
  }

  async get(config?: AxiosRequestConfig, endpoint?: string): Promise<T> {
    const effectiveEndpoint = endpoint || this.endpoint;
    const res = await this.axios.get(`${effectiveEndpoint}`, config);
    return res.data;
  }

  async getById(id: number, config?: AxiosRequestConfig, endpoint?: string): Promise<T> {
    const effectiveEndpoint = endpoint || this.endpoint;
    const response: AxiosResponse<T> = await this.axios.get(`${effectiveEndpoint}/${id}`, config);
    return response.data;
  }

  async create(data: any, config?: AxiosRequestConfig, endpoint?: string): Promise<T> {
    const effectiveEndpoint = endpoint || this.endpoint;
    const response: AxiosResponse<T> = await this.axios.post(effectiveEndpoint, data, config);
    return response.data;
  }

  async fetch(config?: AxiosRequestConfig, endpoint?: string): Promise<{ items: T[]; totalCount: number }> {
    const effectiveEndpoint = endpoint || this.endpoint;
    const response: AxiosResponse<{ items: T[]; totalCount: number }> = await this.axios.get(effectiveEndpoint, config);
    return response.data;
  }

  async patch(id: number, data: any, config?: AxiosRequestConfig, endpoint?: string): Promise<T> {
    const effectiveEndpoint = endpoint || this.endpoint;
    const response: AxiosResponse<T> = await this.axios.patch(`${effectiveEndpoint}/${id}`, data, config);
    return response.data;
  }

  async delete<T>(id: number, config?: AxiosRequestConfig, endpoint?: string): Promise<T> {
    const effectiveEndpoint = endpoint || this.endpoint;
    const response: AxiosResponse<T> = await this.axios.delete(`${effectiveEndpoint}/${id}`, config);
    return response.data;
  }
}
