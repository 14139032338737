import { Filter, Sorting } from '../constants/types';

/**
 * Utility function to build query parameters for API filters
 *
 * @param filters - Array of filtering objects with property, rule, and value
 * @returns An object representing the query parameters for the filters
 */
export const buildFilterQueryParams = (filters: Filter[], sorting?: Sorting): Record<string, string[]> => {
  const params: Record<string, string[]> = {
    filter: [],
    sort: [],
  };

  filters.forEach((filter) => {
    let value: string;

    if (Array.isArray(filter.value)) {
      value = filter.value.join(',');
    } else {
      value = String(filter.value);
    }

    // Construct the filter parameter in the format 'property:rule:value'
    params.filter.push(`${filter.property}:${filter.rule}:${value}`);
  });

  if (sorting) {
    params.sort.push(`${sorting.property}:${sorting.direction}`);
  }

  return params;
};
