import { CourseStore } from './CourseStore';
import { MultipleChoiceStore } from './MultipleChoiceStore';
import { ProblemStore } from './ProblemStore';

export default class RootStore {
  problemStore: ProblemStore;
  multipleChoiceStore: MultipleChoiceStore;
  courseStore: CourseStore;

  constructor() {
    this.problemStore = new ProblemStore();
    this.multipleChoiceStore = new MultipleChoiceStore();
    this.courseStore = new CourseStore();
  }
}
