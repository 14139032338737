import { FaHome, FaList, FaQuestionCircle, FaPlus, FaTags, FaVideo } from 'react-icons/fa';
import { IoMdCheckboxOutline } from 'react-icons/io';

import { Routes } from '../router/routes';
import { SidebarItem } from './types';

export const sidebarRoutes: { [key: string]: SidebarItem } = {
  dashboard: {
    path: Routes.DashboardHome,
    label: 'Dashboard',
    icon: FaHome,
  },
  categories: {
    path: Routes.DashboardCategories,
    label: 'Categories',
    icon: FaList,
  },
  videoCourses: {
    path: Routes.DashboardVideoCourses,
    label: 'Video Courses',
    icon: FaVideo,
  },
  problems: {
    path: Routes.DashboardProblems,
    label: 'Problems',
    icon: FaQuestionCircle,
    subRoutes: {
      list: {
        path: Routes.DashboardProblems,
        label: 'List Problems',
        icon: FaList,
      },
      create: {
        path: Routes.DashboardProblemsCreate,
        label: 'Create Problem',
        icon: FaPlus,
      },
    },
  },
  multipleChoice: {
    path: Routes.DashboardMultipleChoice,
    label: 'Multiple Choice',
    icon: IoMdCheckboxOutline,
    subRoutes: {
      list: {
        path: Routes.DashboardMultipleChoice,
        label: 'List',
        icon: FaList,
      },
      create: {
        path: Routes.DashboardMultipleChoiceCreate,
        label: 'Create',
        icon: FaPlus,
      },
    },
  },
  tags: {
    path: Routes.DashboardTags,
    label: 'Tags',
    icon: FaTags,
  },
};
