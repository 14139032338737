import { Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import { Routes } from './routes';
import HomeScreen from '../screens/HomeScreen';
import LoginScreen from '../screens/auth/LoginScreen';
import DashboardOutlet from '../screens/dashboard/DashboardOutlet';
import DashboardHomeScreen from '../screens/dashboard/DashboardHomeScreen';
import DashboardProblemScreen from '../screens/dashboard/DashboardProblemScreen';
import DashboardMultipleChoiceScreen from '../screens/dashboard/DashboardMultipleChoiceScreen';
import CreateProblemScreen from '../screens/dashboard/CreateProblemScreen';
import CreateMultipleChoiceScreen from '../screens/dashboard/CreateMultipleChoiceScreen';
import CreateCategoryScreen from '../screens/dashboard/CreateCategoryScreen';
import TagsScreen from '../screens/dashboard/TagsScreen';
import UpdateProblemScreen from '../screens/dashboard/UpdateProblemScreen';
import DashboardVideoCoursesScreen from '../screens/dashboard/DashboardVideoCoursesScreen';

import DashboardCourseEditScreen from '../screens/dashboard/DashboardCourseEditScreen';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path={Routes.Home} element={<HomeScreen />} />
      <Route path={Routes.Login} element={<LoginScreen />} />
      <Route path={Routes.Dashboard} element={<DashboardOutlet />}>
        <Route path={Routes.DashboardHome} element={<DashboardHomeScreen />} />
        <Route path={Routes.DashboardProblems} element={<DashboardProblemScreen />} />
        <Route path={Routes.DashboardMultipleChoice} element={<DashboardMultipleChoiceScreen />} />
        <Route path={Routes.DashboardProblemsCreate} element={<CreateProblemScreen />} />
        <Route path={Routes.DashboardMultipleChoiceCreate} element={<CreateMultipleChoiceScreen />} />
        <Route path={Routes.DashboardCategories} element={<CreateCategoryScreen />} />
        <Route path={Routes.DashboardTags} element={<TagsScreen />} />
        <Route path={Routes.DashboardProblemsEdit} element={<UpdateProblemScreen />} />
        <Route path={Routes.DashboardVideoCourses} element={<DashboardVideoCoursesScreen />} />
        <Route path={Routes.DashboardVideoCoursesEdit} element={<DashboardCourseEditScreen />} />
      </Route>
    </>
  )
);
