import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../hooks/useStore';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  VStack,
  Heading,
  useToast,
} from '@chakra-ui/react';
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';

import { ProblemDifficulty } from '../../constants/enums';
import { IProblemTest } from '../../models/problem';
import { MultiSelect } from '../../components/common/MultiSelect';
import { Routes } from '../../router/routes';

const UpdateProblemScreen = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const {
    problemStore: {
      tags,
      loading,
      categories,
      currentProblem,
      fetchCategories,
      fetchTags,
      updateProblem,
      getProblemById,
      updateProblemSolution,
    },
  } = useStore();

  const toast = useToast();

  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [difficulty, setDifficulty] = useState<ProblemDifficulty>(ProblemDifficulty.Easy);
  const [categoryId, setCategoryId] = useState<number>(1);
  const [tests, setTests] = useState<Partial<IProblemTest>[]>([{ input: '', output: '' }]);
  const [selectedTags, setSelectedTags] = useState<any[]>([]);
  const [solutionText, setSolutionText] = useState('');
  const [solutionLoading, setSolutionLoading] = useState(false);

  const inputStyles = {
    border: 'none',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
    bg: 'white',
  };

  useEffect(() => {
    fetchCategories();
    fetchTags();
    if (id) {
      getProblemById(parseInt(id));
    }
  }, [fetchCategories, fetchTags, getProblemById, id]);

  useEffect(() => {
    if (currentProblem) {
      console.log('Current problem is ', currentProblem);
      setTitle(currentProblem.title);
      setText(currentProblem.text);
      setDifficulty(currentProblem.difficulty);
      setCategoryId(currentProblem.category.id);
      setTests(currentProblem.tests);
      setSolutionText(currentProblem.solutions.find((s) => s.userId === 1)?.text || '');
      setSelectedTags(currentProblem.tags.map((tag) => ({ label: tag.name, value: tag.id })));
    }
  }, [currentProblem]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!id) return;

    try {
      await updateProblem(parseInt(id), {
        title,
        text,
        difficulty,
        categoryId,
        tests,
        tags: selectedTags.map((tag) => tag.value),
      });

      toast({
        title: 'Problem updated',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
      navigate(Routes.DashboardProblems);
    } catch (error) {
      toast({
        title: 'Error updating problem',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  const handleAddTest = () => {
    setTests([...tests, { input: '', output: '' }]);
  };

  const handleRemoveTest = (index: number) => {
    setTests(tests.filter((_, i) => i !== index));
  };

  const handleTestChange = (index: number, field: 'input' | 'output', value: string) => {
    const newTests = [...tests];
    newTests[index][field] = value;
    setTests(newTests);
  };

  const handleUpdateSolution = async () => {
    if (!id) return;

    setSolutionLoading(true);
    try {
      const solution = currentProblem?.solutions.find((s) => s.userId === 1);
      await updateProblemSolution(parseInt(id), solution?.id || 0, {
        text: solutionText,
      });

      toast({
        title: 'Solution updated',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
      toast({
        title: 'Error updating solution',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setSolutionLoading(false);
    }
  };

  if (!currentProblem) {
    return <Box>Loading...</Box>;
  }

  return (
    <Box maxWidth={['100%', '100%', '800px']} margin="auto" padding={[4, 6, 8]}>
      <Heading mb={[4, 5, 6]} fontSize={['xl', '2xl', '3xl']}>
        Update Problem
      </Heading>
      <form onSubmit={handleSubmit}>
        <VStack spacing={[4, 5, 6]} align="stretch">
          <FormControl isRequired>
            <FormLabel>Title</FormLabel>
            <Input value={title} onChange={(e) => setTitle(e.target.value)} {...inputStyles} />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Text</FormLabel>
            <Textarea value={text} onChange={(e) => setText(e.target.value)} minHeight="150px" {...inputStyles} />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Difficulty</FormLabel>
            <Select value={difficulty} onChange={(e) => setDifficulty(Number(e.target.value))} {...inputStyles}>
              {Object.entries(ProblemDifficulty)
                .filter(([key]) => isNaN(Number(key)))
                .map(([key, value]) => (
                  <option key={key} value={value}>
                    {key}
                  </option>
                ))}
            </Select>
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Category</FormLabel>
            <Select value={categoryId} onChange={(e) => setCategoryId(Number(e.target.value))} {...inputStyles}>
              {categories?.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.title}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>Tests</FormLabel>
            {tests.map((test, index) => (
              <VStack key={index} mb={4} spacing={2} align="stretch">
                <Textarea
                  placeholder="Input"
                  value={test.input}
                  onChange={(e) => handleTestChange(index, 'input', e.target.value)}
                  {...inputStyles}
                />
                <Textarea
                  placeholder="Output"
                  value={test.output}
                  onChange={(e) => handleTestChange(index, 'output', e.target.value)}
                  {...inputStyles}
                />
                <Button leftIcon={<DeleteIcon />} onClick={() => handleRemoveTest(index)} size="sm" variant="outline">
                  Remove Test
                </Button>
              </VStack>
            ))}
            <Button leftIcon={<AddIcon />} onClick={handleAddTest} size="sm" mt={2}>
              Add Test
            </Button>
          </FormControl>
          <FormControl>
            <FormLabel>Tags</FormLabel>
            <MultiSelect
              label="Tags"
              value={selectedTags || []}
              onChange={(values) => setSelectedTags(values)}
              options={tags.map((tag) => ({ label: tag.name, value: tag.id }))}
            />
          </FormControl>
          <Button type="submit" colorScheme="primary" isLoading={loading} size={['md', 'lg']} width="100%" mt={4}>
            Update Problem
          </Button>

          <FormControl mt={6}>
            <FormLabel>Update Solution</FormLabel>
            <Textarea
              value={solutionText}
              onChange={(e) => setSolutionText(e.target.value)}
              placeholder="Enter updated solution text"
              minHeight="150px"
              {...inputStyles}
            />
          </FormControl>
          <Button
            mt={4}
            width="100%"
            colorScheme="primary"
            isLoading={solutionLoading}
            size={['md', 'lg']}
            onClick={handleUpdateSolution}>
            Update Solution
          </Button>
        </VStack>
      </form>
    </Box>
  );
};

export default observer(UpdateProblemScreen);
