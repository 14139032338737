import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Stack,
  Input,
  Select,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  Text,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { ProblemDifficulty } from '../../constants/enums';
import { useStore } from '../../hooks/useStore';

const inputStyles = {
  border: 'none',
  boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
  bg: 'white',
};

const CreateCategoryScreen = () => {
  const [title, setTitle] = useState('');
  const [difficulty, setDifficulty] = useState<ProblemDifficulty>(ProblemDifficulty.Easy);
  const [loading, setLoading] = useState(false);
  const {
    problemStore: { categories, fetchCategories, createCategory },
  } = useStore();

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  const handleCreateCategory = async () => {
    setLoading(true);
    await createCategory({ title, difficulty });
    setTitle('');
    setDifficulty(ProblemDifficulty.Easy);
    setLoading(false);
  };

  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Box padding={[4, 6, 8]}>
      <Text fontSize={['xl', '2xl']} fontWeight="bold" mt={12} mb={4}>
        Categories
      </Text>
      <Divider />
      <Stack mt={8} direction={['column', 'column', 'row']} spacing={4} alignItems={['stretch', 'stretch', 'flex-end']}>
        <FormControl flex={2} isRequired>
          <FormLabel>Title</FormLabel>
          <Input value={title} onChange={(e) => setTitle(e.target.value)} {...inputStyles} />
        </FormControl>
        <FormControl flex={2} isRequired>
          <FormLabel>Difficulty</FormLabel>
          <Select value={difficulty} onChange={(e) => setDifficulty(Number(e.target.value))} {...inputStyles}>
            {Object.entries(ProblemDifficulty)
              .filter(([key]) => isNaN(Number(key)))
              .map(([key, value]) => (
                <option key={key} value={value}>
                  {key}
                </option>
              ))}
          </Select>
        </FormControl>
        <Button
          flex={1}
          colorScheme="primary"
          isLoading={loading}
          onClick={handleCreateCategory}
          width={['100%', '100%', 'auto']}>
          Create
        </Button>
      </Stack>
      <Box overflowX="auto" mt={16}>
        <Table variant="striped" colorScheme="gray" size={isMobile ? 'sm' : 'md'}>
          <Thead>
            <Tr>
              <Th>ID</Th>
              <Th>Title</Th>
              <Th>Difficulty</Th>
            </Tr>
          </Thead>
          <Tbody>
            {categories.map((category) => (
              <Tr key={category.id}>
                <Td>{category.id}</Td>
                <Td>{category.title}</Td>
                <Td>{category.difficulty}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
};

export default observer(CreateCategoryScreen);
