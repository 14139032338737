import { BaseApi } from '.';
import { IMultipleChoice } from '../models/multiple-choice';
import { endpoints } from './endpoints';

export class MultipleChoiceApi extends BaseApi<IMultipleChoice> {
  constructor() {
    super(endpoints.multipleChoice.base);
  }

  checkAnswer(questionId: number, answerId: number) {
    return this.post({ questionId, answerId }, endpoints.multipleChoice.check);
  }
}
