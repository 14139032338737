import React, { useEffect } from 'react';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Badge,
  Tooltip,
  useBreakpointValue,
  Divider,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../hooks/useStore';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../router/routes';

const DashboardMultipleChoiceScreen = () => {
  const {
    multipleChoiceStore: { fetchMultipleChoiceQuestions, questions },
  } = useStore();

  const navigate = useNavigate();

  useEffect(() => {
    fetchMultipleChoiceQuestions();
  }, [fetchMultipleChoiceQuestions]);

  const handleRowClick = (questionId: number) => {
    navigate(Routes.DashboardMultipleChoiceEdit.replace(':id', questionId.toString()));
  };

  // Use responsive layout for table columns
  const tableLayout = useBreakpointValue({
    base: 'stack',
    md: 'fixed',
  });

  // Adjust visible columns based on screen size
  const showDifficulty = useBreakpointValue({ base: false, md: true });
  const showAnswers = useBreakpointValue({ base: false, lg: true });

  return (
    <Box overflowX="auto">
      <Text fontSize={['xl', '2xl']} fontWeight="bold" mt={12} mb={4}>
        Multiple Choice Questions
      </Text>
      <Divider />
      <Table variant="striped" colorScheme="gray" layout={tableLayout}>
        <Thead>
          <Tr>
            <Th>ID</Th>
            <Th>Title</Th>
            {showDifficulty && <Th>Difficulty</Th>}
            {showAnswers && <Th>Answers</Th>}
          </Tr>
        </Thead>
        <Tbody>
          {questions.map((question) => (
            <Tr
              key={question.id}
              onClick={() => handleRowClick(question.id)}
              cursor="pointer"
              _hover={{ bg: 'gray.100' }}>
              <Td>{question.id}</Td>
              <Td>{question.title}</Td>
              {showDifficulty && <Td>{question.difficulty}</Td>}
              {showAnswers && (
                <Td>
                  {question.answers.map((answer) => (
                    <Tooltip key={answer.id} label={answer.text}>
                      <Badge mr={2} mb={2} colorScheme={answer.isCorrect ? 'primary' : 'gray'} variant="subtle">
                        {answer.text.length > 20 ? `${answer.text.substring(0, 20)}...` : answer.text}
                      </Badge>
                    </Tooltip>
                  ))}
                </Td>
              )}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default observer(DashboardMultipleChoiceScreen);
